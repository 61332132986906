<header id="main-header" class="py-3">
  <nav class="navbar navbar-expand-lg">
    <div class="container flex-lg-row flex-nowrap align-items-center position-relative">
      <div class="navbar-brand w-100 m-0">
        <figure id="logotype" class="m-0 h-50" routerLink="/" role="button">
          <img src="./assets/images/logotype-large-white.svg" alt="" class="w-100 h-100">
        </figure>
      </div>

      <div class="navbar-other w-100 d-flex ms-auto">
        <ul class="navbar-nav flex-row align-items-center ms-auto">
          <!-- <li class="nav-item mx-2">
            <button type="button" class="btn btn-outline-light rounded-5 w-max-content py-2 px-4" routerLink="/">
              Contacto
            </button>
          </li> -->

          <!-- <li class="nav-item d-block d-lg-none">
            <p-button icon="pi pi-bars" [text]="true" styleClass="text-light" (click)="open(offcanvas)"></p-button>
          </li> -->
        </ul>
      </div>
    </div>
  </nav>
</header>

<!-- <header id="main-header-menu" class="d-none d-lg-block">
  <nav class="navbar navbar-expand-lg">
    <div class="container flex-lg-row flex-nowrap align-items-center position-relative">
      <div class="w-100">
        <ng-container *ngTemplateOutlet="mainMenu"></ng-container>
      </div>
    </div>
  </nav>
</header> -->

<ng-template #mainMenu>
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link fw-medium w-max-content" routerLink="/" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">Inicio</a>
    </li>
    <li class="nav-item" ngbDropdown>
      <button type="button" class="nav-link" [class.active]="routerLinkActive.isActive"
        ngbDropdownToggle>Transportación</button>
      <div ngbDropdownMenu>
        <button ngbDropdownItem class="py-2 px-4" routerLink="" routerLinkActive
          #routerLinkActive="routerLinkActive">Cancún Aeropuerto</button>
        <button ngbDropdownItem class="py-2 px-4" routerLink="" routerLinkActive
          #routerLinkActive="routerLinkActive">Cancún a Playa del Carmen</button>
        <button ngbDropdownItem class="py-2 px-4" routerLink="" routerLinkActive
          #routerLinkActive="routerLinkActive">Cancún a Tulúm</button>
      </div>
    </li>
    <li class="nav-item dropdown-mega" ngbDropdown>
      <button type="button" class="nav-link" ngbDropdownToggle>Tours</button>

      <ul ngbDropdownMenu class="mega-menu">
        <li class="mega-menu-content mega-menu-scroll">
          <ul class="row row-cols-1 row-cols-lg-4 row-cols-xl-6 gx-0 gx-lg-4 gy-lg-2 list-unstyled">
            <li class="col">
              <a ngbDropdownItem role="button">
                <figure class="d-none d-lg-block mb-2 bg-dark-subtle rounded-3 p-4" style="height: 120px;">
                  <img class="w-100" src="./assets/images/resources/block10.svg" alt="">
                </figure>
                <span class="px-2">Vida Nocturna</span>
              </a>
            </li>
            <li class="col">
              <a ngbDropdownItem role="button">
                <figure class="d-none d-lg-block mb-2 bg-dark-subtle rounded-3 p-4" style="height: 120px;">
                  <img class="w-100" src="./assets/images/resources/block10.svg" alt="">
                </figure>
                <span class="px-2">Chichen Itzá</span>
              </a>
            </li>
            <li class="col">
              <a ngbDropdownItem role="button">
                <figure class="d-none d-lg-block mb-2 bg-dark-subtle rounded-3 p-4" style="height: 120px;">
                  <img class="w-100" src="./assets/images/resources/block10.svg" alt="">
                </figure>
                <span class="px-2">Tulúm</span>
              </a>
            </li>
            <li class="col">
              <a ngbDropdownItem role="button">
                <figure class="d-none d-lg-block mb-2 bg-dark-subtle rounded-3 p-4" style="height: 120px;">
                  <img class="w-100" src="./assets/images/resources/block10.svg" alt="">
                </figure>
                <span class="px-2">Catamarán a Isla Mujeres</span>
              </a>
            </li>
            <li class="col">
              <a ngbDropdownItem role="button">
                <figure class="d-none d-lg-block mb-2 bg-dark-subtle rounded-3 p-4" style="height: 120px;">
                  <img class="w-100" src="./assets/images/resources/block10.svg" alt="">
                </figure>
                <span class="px-2">Ventura Park</span>
              </a>
            </li>
            <li class="col">
              <a ngbDropdownItem role="button">
                <figure class="d-none d-lg-block mb-2 bg-dark-subtle rounded-3 p-4" style="height: 120px;">
                  <img class="w-100" src="./assets/images/resources/block10.svg" alt="">
                </figure>
                <span class="px-2">Xcaret</span>
              </a>
            </li>
            <li class="col">
              <a ngbDropdownItem role="button">
                <figure class="d-none d-lg-block mb-2 bg-dark-subtle rounded-3 p-4" style="height: 120px;">
                  <img class="w-100" src="./assets/images/resources/block10.svg" alt="">
                </figure>
                <span class="px-2">Xel-Ha</span>
              </a>
            </li>
            <li class="col">
              <a ngbDropdownItem role="button">
                <figure class="d-none d-lg-block mb-2 bg-dark-subtle rounded-3 p-4" style="height: 120px;">
                  <img class="w-100" src="./assets/images/resources/block10.svg" alt="">
                </figure>
                <span class="px-2">Xplor</span>
              </a>
            </li>
          </ul>

          <span class="d-none d-lg-flex">
            <i class="bi bi-arrow-down-up me-2"></i><strong>Scrollea para ver más</strong>
          </span>
        </li>
      </ul>
    </li>
    <li class="nav-item">
      <button type="button" class="nav-link">Blog</button>
    </li>
  </ul>
</ng-template>

<ng-template #offcanvas let-offcanvas>
  <div class="offcanvas-header justify-content-between">
    <figure id="logotype" class="m-0" [routerLink]="'.'" style="width: 150px;">
      <img src="./assets/images/logotype-large.svg" alt="Logo">
    </figure>
    <p-button icon="pi pi-times" [rounded]="true" [text]="true" (click)="offcanvas.dismiss()"></p-button>
  </div>
  <div class="offcanvas-body d-flex flex-column h-100">
    <ng-container *ngTemplateOutlet="mainMenu"></ng-container>

    <div class="offcanvas-footer">
      <div>
        <a href="mailto:hello@example.com" class="link-inverse">{{'hello@example.com'}}</a>
        <br> 00 (123) 456 78 90 <br>
        <nav class="nav social mt-4">
          <a role="button"><i class="bi bi-twitter-x"></i></a>
          <a role="button"><i class="bi bi-facebook"></i></a>
          <a role="button"><i class="bi bi-instagram"></i></a>
          <a role="button"><i class="bi bi-threads"></i></a>
          <a role="button"><i class="bi bi-tiktok"></i></a>
        </nav>
      </div>
    </div>
  </div>
</ng-template>