import { Component, inject } from '@angular/core'
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms'
import { InputTextModule } from 'primeng/inputtext'
import { DropdownModule } from 'primeng/dropdown'
import { AutoCompleteModule } from 'primeng/autocomplete'
import { ButtonModule } from 'primeng/button'
import { InputNumberModule } from 'primeng/inputnumber';
import { CalendarModule } from 'primeng/calendar';
import { ActivatedRoute, Router } from '@angular/router'
import { TransportationService } from '@services/transportation.service'

interface ICatalog {
  typeService: any[],
  departure: any[],
  destination: any[],
  currency: any[]
}

interface AutoCompleteCompleteEvent {
  originalEvent: Event
  query: string
}

@Component({
  selector: 'booking-box',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputTextModule,
    DropdownModule,
    AutoCompleteModule,
    ButtonModule,
    InputNumberModule,
    CalendarModule
  ],
  templateUrl: './booking-box.component.html',
  styleUrl: './booking-box.component.scss'
})
export class BookingBoxComponent {
  private formBuilder = inject(FormBuilder)
  private router = inject(Router)
  private activatedRoute = inject(ActivatedRoute)
  private transportationService = inject(TransportationService)

  public ifSearching = false

  public form = this.formBuilder.group({
    typeService: ['ROUNDTRIP', Validators.required],
    departure: [null, Validators.required],
    destination: [null, Validators.required],
    arrivalDate: [null],
    departureDate: [null],
    adults: [1, Validators.required],
    childrens: [0, Validators.required],
    currency: ['MXN', Validators.required],
  })

  get typeService() { return this.form.get('typeService') }
  set typeService(value: any) { this.typeService.setValue(value) }
  get departure() { return this.form.get('departure') }
  set departure(value: any) { this.departure.setValue(value) }
  get destination() { return this.form.get('destination') }
  set destination(value: any) { this.destination.setValue(value) }
  get arrivalDate() { return this.form.get('arrivalDate') }
  set arrivalDate(value: any) { this.arrivalDate.setValue(value) }
  get departureDate() { return this.form.get('departureDate') }
  set departureDate(value: any) { this.departureDate.setValue(value) }
  get adults() { return this.form.get('adults') }
  set adults(value: any) { this.adults.setValue(value) }
  get childrens() { return this.form.get('childrens') }
  set childrens(value: any) { this.childrens.setValue(value) }
  get currency() { return this.form.get('currency') }
  set currency(value: any) { this.currency.setValue(value) }

  public catalog: ICatalog = {
    // typeService: [
    //   { value: 'ROUNDTRIP_AIRPORT_HOTEL', label: { departure: 'Aeropuerto', destination: 'Hotel', roundtrip: true } },
    //   { value: 'ONEWAY_AIRPORT_HOTEL', label: { departure: 'Aeropuerto', destination: 'Hotel', roundtrip: false } },
    //   { value: 'ONEWAY_HOTEL_AIRPORT', label: { departure: 'Hotel', destination: 'Aeropuerto', roundtrip: false } },
    //   { value: 'ONEWAY_HOTEL_HOTEL', label: { departure: 'Hotel', destination: 'Hotel', roundtrip: false } },
    //   { value: 'ROUNDTRIP_HOTEL_HOTEL', label: { departure: 'Hotel', destination: 'Hotel', roundtrip: true } },
    // ],
    typeService: [
      { value: 'ROUNDTRIP', label: 'Redondo' },
      { value: 'ONEWAY', label: 'Sencillo' },
    ],
    departure: [],
    destination: [],
    currency: [
      // { value: 'USD', label: 'USD' },
      { value: 'MXN', label: 'MXN' }
    ]
  }
  public minDateArrival: Date = new Date()
  public minDateDeparture: Date = new Date()

  async ngOnInit() {
    await this.transportationService.getLocations().then((response: any) => {
      this.catalog.departure = response
      this.catalog.destination = response
    })

    this.minDateArrival.setDate(this.minDateArrival.getDate() + 1)
    this.minDateArrival.setHours(12, 0, 0, 0)

    this.arrivalDate = this.minDateArrival

    this.activatedRoute.params.subscribe(async routeParams => {
      let objParams = this.transportationService.paramsFindRateParse(routeParams)

      if (objParams.typeService !== null)
        this.typeService = objParams.typeService

      if (objParams.departure !== null)
        this.departure = objParams.departure

      if (objParams.destination !== null)
        this.destination = objParams.destination

      if (objParams.dates.arrival !== null)
        this.arrivalDate = objParams.dates.arrival

      if (objParams.dates.departure !== null)
        this.departureDate = objParams.dates.departure

      if (objParams.pax.adults !== null)
        this.adults = objParams.pax.adults

      if (objParams.pax.childrens !== null)
        this.childrens = objParams.pax.childrens

      if (objParams.currency !== null)
        this.currency = objParams.currency

      if (this.typeService.value === 'ONEWAY') {
        this.departureDate = null
        this.departureDate.disable()
      }
    });

    this.arrivalDate.valueChanges.subscribe((change: any) => {
      this.onChangeArrivalDate()
    })
  }

  onChangeArrivalDate() {
    this.minDateDeparture = new Date(this.arrivalDate.value)
    this.minDateDeparture.setDate(this.minDateDeparture.getDate())
    this.minDateDeparture.setTime(this.minDateDeparture.getTime() + (1 * 60 * 60 * 1000))

    if (this.typeService.value === 'ROUNDTRIP') {
      this.departureDate = this.minDateDeparture
    }
  }

  onChangeTypeService() {
    switch (this.typeService.value) {
      // case 'ROUNDTRIP_AIRPORT_HOTEL':
      //   this.departureDate.enable()
      //   break;
      // case 'ONEWAY_AIRPORT_HOTEL':
      //   this.departureDate.disable()
      //   break;
      // case 'ONEWAY_HOTEL_AIRPORT':
      //   this.departureDate.disable()
      //   break;
      // case 'ONEWAY_HOTEL_HOTEL':
      //   this.departureDate.disable()
      //   break;
      // case 'ROUNDTRIP_HOTEL_HOTEL':
      //   this.departureDate.enable()
      //   break;
      case 'ROUNDTRIP':
        this.departureDate.enable()

        if (this.arrivalDate.value !== null) {
          this.onChangeArrivalDate()
        }
        break;
      case 'ONEWAY':
        this.departureDate.disable()
        this.departureDate = null
        break;
    }
  }

  search() {
    this.ifSearching = true
    let rootPath = `/choose-your-transfer`

    let typeService = ''
    if (this.typeService.value != null) {
      typeService += this.typeService.value
    }

    let destinations = ''
    if (this.departure.value != null) {
      destinations += this.departure.value
    }
    destinations += ':'
    if (this.destination.value != null) {
      destinations += this.destination.value
    }

    let dates = ''
    if (this.arrivalDate.value != null) {
      dates += new Date(this.arrivalDate.value).getTime()
    }
    dates += ':'
    if (this.departureDate.value != null) {
      dates += new Date(this.departureDate.value).getTime()
    }

    let pax = `${this.adults.value}:${this.childrens.value}`
    let currency = `${this.currency.value}`

    this.router.navigate([rootPath, typeService, destinations, dates, pax, currency]);
  }
}
