import { CommonModule } from '@angular/common';
import { Component, TemplateRef, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbCollapseModule, NgbDropdownModule, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'main-header',
  standalone: true,
  imports: [CommonModule, NgbCollapseModule, RouterModule, ButtonModule, NgbDropdownModule],
  templateUrl: './main-header.component.html',
  styleUrl: './main-header.component.scss'
})
export class MainHeaderComponent {
  private offcanvasService = inject(NgbOffcanvas);

  open = (content: TemplateRef<any>) => this.offcanvasService.open(content, { ariaLabelledBy: 'offcanvas-basic-title' })
}
