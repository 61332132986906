import { Routes } from '@angular/router';
import { ChooseTransferPage } from './choose-transfer.component';

export const routes: Routes = [
  {
    path: 'choose-your-transfer/:typeService/:destinations/:dates/:pax/:currency',
    component: ChooseTransferPage,
    title: 'Elije tu traslado',
  },
];
