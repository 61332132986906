<footer id="main-footer" class="py-5 text-bg-primary rounded-top-3">
  <div class="container">
    <div class="d-flex flex-column align-items-center">
      <figure class="logotype mb-5" routerLink="/" role="button">
        <img src="./assets/images/logotype-large-white.svg" alt="Logotype" width="300px">
      </figure>

      <div class="assistance mb-5 w-100">
        <div class="row">
          <div class="col-12 col-lg-4 text-center d-flex justify-content-center align-items-center">
            <h4 class="m-0 fw-bold text-decoration-underline"><small>{{'hello@mydomain.com'}}</small></h4>
          </div>
          <div class="col-12 col-lg-4 text-center d-flex justify-content-center align-items-center">
            <a href="https://api.whatsapp.com/send?phone=" target="_blank"
              class="btn btn-outline-light rounded-pill px-5 py-3 d-flex justify-content-center align-items-center gap-2"><i
                class="bi bi-whatsapp fs-4"></i> WhatsApp</a>
          </div>
          <div class="col-12 col-lg-4 text-center d-flex justify-content-center align-items-center">
            <h4 class="m-0 fw-bold text-decoration-underline"><small>{{'00 (123) 456 78 90'}}</small></h4>
          </div>
        </div>
      </div>

      <p class="copyright fw-medium m-0"><small>2024 Todos los derechos reservados.</small></p>

      <ul class="links list-unstyled d-flex align-items-center m-0">
        <li class="links-items fw-medium">
          <a href="https://" class="text-white">Términos y Condiciones</a>
        </li>
        <li class="links-items fw-medium">
          <a href="https://" class="text-white">Aviso de Privacidad</a>
        </li>
      </ul>
    </div>

    <p class="m-0 mt-4 text-center w-lg-75 mx-auto"><small>Usar este sitio implica que aceptas nuestras Politicas y
        Términos, Aviso de Privacidad y Politica datos de Navegación. Prohibida su reproduccion total o parcial, asi
        como su traducción a cualquier idioma sin autorización escrita de su titular.</small></p>
  </div>
</footer>