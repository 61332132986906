<main-header></main-header>

<section class="py-5 text-bg-light" style="min-height: 100vh;">
  <div class="container">
    @if (loading === false) {
    <div class="text-center">
      @if (this.data.status === 'CREATED' || this.data.status === 'PENDING') {
      <div class="mb-4 text-warning">
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" class="bi bi-clock-history"
          viewBox="0 0 16 16">
          <path
            d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7 7 0 0 0-.985-.299l.219-.976q.576.129 1.126.342zm1.37.71a7 7 0 0 0-.439-.27l.493-.87a8 8 0 0 1 .979.654l-.615.789a7 7 0 0 0-.418-.302zm1.834 1.79a7 7 0 0 0-.653-.796l.724-.69q.406.429.747.91zm.744 1.352a7 7 0 0 0-.214-.468l.893-.45a8 8 0 0 1 .45 1.088l-.95.313a7 7 0 0 0-.179-.483m.53 2.507a7 7 0 0 0-.1-1.025l.985-.17q.1.58.116 1.17zm-.131 1.538q.05-.254.081-.51l.993.123a8 8 0 0 1-.23 1.155l-.964-.267q.069-.247.12-.501m-.952 2.379q.276-.436.486-.908l.914.405q-.24.54-.555 1.038zm-.964 1.205q.183-.183.35-.378l.758.653a8 8 0 0 1-.401.432z" />
          <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z" />
          <path
            d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5" />
        </svg>
      </div>

      <h2 class="text-warning">Pago pendiente!</h2>
      <p class="fs-5 fw-normal text-muted mb-5">Tu pago se ecuentra pendiente. <br>Te notificaremos cuando se haya
        completado.</p>

      <button class="btn btn-primary py-3 px-5 rounded-pill" routerLink="/">Regresar al inicio</button>
      }

      @else if (this.data.status === 'CANCELLED') {
      <div class="mb-4 text-danger">
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" class="bi bi-ban"
          viewBox="0 0 16 16">
          <path
            d="M15 8a6.97 6.97 0 0 0-1.71-4.584l-9.874 9.875A7 7 0 0 0 15 8M2.71 12.584l9.874-9.875a7 7 0 0 0-9.874 9.874ZM16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0" />
        </svg>
      </div>

      <h2 class="text-danger">Pago cancelado!</h2>
      <p class="fs-5 fw-normal text-muted mb-5">Tu pago se cancelo, tuvimos algún problema para cobrarte.</p>

      <button class="btn btn-primary py-3 px-5 rounded-pill" routerLink="/">Regresar al inicio</button>
      }

      @else if (this.data.status === 'EXPIRED') {
      <div class="mb-4 text-danger">
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" class="bi bi-clock"
          viewBox="0 0 16 16">
          <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
          <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0" />
        </svg>
      </div>

      <h2 class="text-danger">Pago expirado!</h2>
      <p class="fs-5 fw-normal text-muted mb-5">El link de pago expiró.</p>

      <button class="btn btn-primary py-3 px-5 rounded-pill" routerLink="/">Regresar al inicio</button>
      }

      @else if (this.data.status === 'COMPLETED') {
      <div class="mb-4 text-success">
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" class="bi bi-check-circle"
          viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
          <path
            d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
        </svg>
      </div>

      <h2 class="text-success">Pago completo!</h2>
      <p class="fs-5 fw-normal text-muted mb-5">Recibiras un correo eletrónico con la información de tu compra.</p>

      <div class="card border-0 mx-auto mb-5 rounded-3 shadow-sm" style="max-width: 600px;">
        <div class="card-body p-5">
          <h1 class="card-title text-success fw-bold m-0">{{data.amount | currency: 'MXN'}} {{data.currency}}</h1>
          <p class="fs-5 fw-normal text-muted mb-4">{{data.description}}</p>

          <h5 class="card-title">Detalles de la compra</h5>
          <p class="card-text">Revisa los detalles de tu compra.</p>

          <table class="table table-borderless">
            <tbody>
              <tr>
                <th scope="row" class="text-end py-1">Referencia</th>
                <td class="text-start py-1"><small>{{data.uuid}}</small></td>
              </tr>
              <tr>
                <th scope="row" class="text-end py-1">Nombre completo</th>
                <td class="text-start py-1">{{data.name}} {{data.lastname}}</td>
              </tr>
              <tr>
                <th scope="row" class="text-end py-1">Correo electrónico</th>
                <td class="text-start py-1">{{data.email}}</td>
              </tr>
              <tr>
                <th scope="row" class="text-end py-1">Terminal de pago</th>
                <td class="text-start py-1">{{data.gateway}}</td>
              </tr>
              <tr>
                <th scope="row" class="text-end py-1">Fecha de pago</th>
                <td class="text-start py-1 text-capitalize">{{data.modified_at | date: 'MMM d, y, h:mm:ss a'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <button class="btn btn-success py-3 px-5 rounded-pill" routerLink="/">Regresar al inicio</button>
      }

      @else {
      <div class="mb-4 text-danger">
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="currentColor" class="bi bi-emoji-dizzy"
          viewBox="0 0 16 16">
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
          <path
            d="M9.146 5.146a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708.708l-.647.646.647.646a.5.5 0 0 1-.708.708l-.646-.647-.646.647a.5.5 0 1 1-.708-.708l.647-.646-.647-.646a.5.5 0 0 1 0-.708m-5 0a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 1 1 .708.708l-.647.646.647.646a.5.5 0 1 1-.708.708L5.5 7.207l-.646.647a.5.5 0 1 1-.708-.708l.647-.646-.647-.646a.5.5 0 0 1 0-.708M10 11a2 2 0 1 1-4 0 2 2 0 0 1 4 0" />
        </svg>
      </div>

      <h2 class="text-danger">Upss...</h2>
      <p class="fs-5 fw-normal text-muted mb-5">El link de pago no existe.</p>

      <button class="btn btn-primary py-3 px-5 rounded-pill" routerLink="/">Regresar al inicio</button>
      }
    </div>
    }
    @else {
    <div class="loader mx-auto mt-5 opacity-25"></div>
    }
  </div>
</section>

<main-footer></main-footer>