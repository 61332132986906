import { inject, Injectable } from '@angular/core';
import { HttpService } from '@utils/http.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private http = inject(HttpService)

  public getPayment = async (uuid: string) => await this.http.get(`/v1/payment/status/${uuid}`, false, false, false)
    .then((response: any) => response.data.content)
    .catch((error: any) => error)
}
