import { Routes } from '@angular/router';
import { PaymentStatusPage } from './payment-status.component';

export const routes: Routes = [
  {
    path: 'redirection/payment',
    component: PaymentStatusPage,
    title: 'Estado del pago',
  },
];
