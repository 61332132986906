import { CommonModule, CurrencyPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MainFooterComponent } from '@components/main-footer/main-footer.component';
import { MainHeaderComponent } from '@components/main-header/main-header.component';
import { PaymentService } from '@services/payment.service';

@Component({
  selector: 'app-payment-status',
  standalone: true,
  imports: [
    MainHeaderComponent,
    MainFooterComponent,
    RouterModule,
    CurrencyPipe,
    CommonModule
  ],
  templateUrl: './payment-status.component.html',
  styleUrl: './payment-status.component.scss'
})
export class PaymentStatusPage {
  private activatedRoute = inject(ActivatedRoute)
  private router = inject(Router)
  private paymentService = inject(PaymentService)

  public loading: boolean = true
  public data: any = {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(async queryParams => {
      let meReferenceId = queryParams["me_reference_id"];

      if (meReferenceId === undefined) {
        this.router.navigate(['/404']);
      }

      this.paymentService.getPayment(meReferenceId).then((response: any) => {
        this.loading = false;
        this.data = response
      });
    })
  }
}
