<main-header></main-header>

<section class="text-bg-secondary py-5 rounded-bottom-3">
  <div class="container">
    <booking-box></booking-box>
  </div>
</section>

<section class="py-5">
  <div class="container">
    <p-stepper [linear]="true" [(activeStep)]="activeIndexStepper">
      <p-stepperPanel header="Elije tu traslado.">
        <ng-template pTemplate="content" let-nextCallback="nextCallback" let-index="index">
          @for (zone of this.results; track $index) {
          @for (vehicle of zone.vehicles; track $index) {
          <div class="card-horizontal d-flex flex-column flex-lg-row gap-4 border-bottom mb-5 pb-5 mb-lg-0 pb-lg-0">
            <div class="card-horizontal__image w-100 w-md-25">
              <figure class="h-100 w-100 m-0">
                <img src="{{vehicle.hero.uri}}" alt="" class="w-100 h-100 object-fit-cover">
              </figure>
            </div>
            <div class="card-horizontal__content d-flex flex-column justify-content-center w-100">
              <h1 class="card-horizontal__title fs-4">
                {{zone.departure.label}}
                @switch (zone.typeService) {
                @case ('ROUNDTRIP') {<i class="bi bi-arrow-left-right mx-4"></i>}
                @case ('ONEWAY') {<i class="bi bi-arrow-right mx-4"></i>}
                }
                {{zone.destination.label}}
              </h1>
              <p>Reserva tu traslado desde el aeropuerto a tu hotel en la zona hotelera.</p>

              @if (vehicle.categoryPrice === 1) {
              <div class="mb-2">
                <p class="m-0 text-bg-secondary d-inline-block px-1">TAXI - El viaje es compartido por más usuarios.
                </p>
              </div>
              }

              <div class="d-flex justify-content-between">
                <div class="d-flex flex-column w-100">
                  @switch (vehicle.categoryPrice) {
                  @case (1) {
                  <p class="m-0 fs-1 fw-semibold lh-1 text-primary">{{zone.category1Price | currency: 'MXN'}} MXN</p>
                  }
                  @case (2) {
                  <p class="m-0 fs-1 fw-semibold lh-1 text-primary">{{zone.category2Price | currency: 'MXN'}} MXN</p>
                  }
                  @case (3) {
                  <p class="m-0 fs-1 fw-semibold lh-1 text-primary">{{zone.category3Price | currency: 'MXN'}} MXN</p>
                  }
                  @case (4) {
                  <p class="m-0 fs-1 fw-semibold lh-1 text-primary">{{zone.category4Price | currency: 'MXN'}} MXN</p>
                  }
                  @case (5) {
                  <p class="m-0 fs-1 fw-semibold lh-1 text-primary">{{zone.category5Price | currency: 'MXN'}} MXN</p>
                  }
                  @default {
                  <p class="m-0 fs-1 fw-semibold lh-1 text-primary">{{zone.basePrice | currency: 'MXN'}} MXN</p>
                  }
                  }
                  <p class="m-0">Impuestos incluidos.</p>
                </div>

                <button
                  class="btn btn-success d-flex justify-content-center align-items-center w-fit-content gap-3 px-4 text-nowrap"
                  (click)="bookingDetails(zone, vehicle)">Reservar ahora <i
                    class="bi bi-arrow-right-short fs-1"></i></button>
              </div>
            </div>
          </div>
          }

          @empty {
          <div class="d-flex justify-content-center align-items-center w-100 h-100 py-5">
            <p class="opacity-50 m-0">No se encontraron resultados.</p>
          </div>
          }
          }

          @empty {
          <div class="d-flex justify-content-center align-items-center w-100 h-100 py-5">
            <p class="opacity-50 m-0">No se encontraron resultados.</p>
          </div>
          }
        </ng-template>
      </p-stepperPanel>

      <p-stepperPanel header="Tu información.">
        <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback"
          let-index="index">
          <div class="row">
            <div class="col-lg-8">
              <form [formGroup]="formDetails">
                <div class="p-4 border rounded-1 mb-5">
                  <h4 class="mb-4"><span class="text-body-tertiary">01</span> Información de contacto.</h4>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3 d-flex flex-column gap-2">
                        <input formControlName="name" type="text" pInputText class="w-100 h-50px" />
                        <small class="ps-2">Nombre(s). <span class="text-danger">Requerido *</span></small>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 d-flex flex-column gap-2">
                        <input formControlName="lastname" type="text" pInputText class="w-100 h-50px" />
                        <small class="ps-2">Apellidos. <span class="text-danger">Requerido *</span></small>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 d-flex flex-column gap-2">
                        <input formControlName="email" type="text" pInputText class="w-100 h-50px" />
                        <small class="ps-2">Correo electrónico. <span class="text-danger">Requerido *</span></small>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 d-flex flex-column gap-2">
                        <input formControlName="phone" type="text" pInputText class="w-100 h-50px" />
                        <small class="ps-2">Número telefónico. <span class="text-danger">Requerido *</span></small>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 d-flex flex-column gap-2">
                        <input formControlName="country" type="text" pInputText class="w-100 h-50px" />
                        <small class="ps-2">País. <span class="text-danger">Requerido *</span></small>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 d-flex flex-column gap-2">
                        <input formControlName="city" type="text" pInputText class="w-100 h-50px" />
                        <small class="ps-2">Ciudad. <span class="text-danger">Requerido *</span></small>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="p-4 border rounded-1 mb-5">
                  <h4 class="mb-4"><span class="text-body-tertiary">02</span> Información del viaje.</h4>

                  <h6 class="mb-2">Información de llegada</h6>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3 d-flex flex-column gap-2">
                        <input formControlName="flyArrivalName" type="text" pInputText class="w-100 h-50px" />
                        <small class="ps-2">Aerolinea.</small>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 d-flex flex-column gap-2">
                        <input formControlName="flyArrivalNumber" type="text" pInputText class="w-100 h-50px" />
                        <small class="ps-2">Número de vuelo.</small>
                      </div>
                    </div>
                  </div>

                  <h6 class="mb-2">Información de salida</h6>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3 d-flex flex-column gap-2">
                        <input formControlName="flyDepartureName" type="text" pInputText class="w-100 h-50px" />
                        <small class="ps-2">Aerolinea.</small>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 d-flex flex-column gap-2">
                        <input formControlName="flyDepartureNumber" type="text" pInputText class="w-100 h-50px" />
                        <small class="ps-2">Número de vuelo.</small>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="p-4 border rounded-1">
                  <h4 class="mb-4"><span class="text-body-tertiary">03</span> Solicitud especial e instrucciones.</h4>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3 d-flex flex-column gap-2">
                        <p-dropdown formControlName="childSeat" [options]="this.valueTrueOrFalse" optionLabel="label"
                          optionValue="value" styleClass="w-100 h-50px">
                          <ng-template let-selected pTemplate="selectedItem">
                            <div class="d-flex">
                              {{selected.label}}
                            </div>
                          </ng-template>
                        </p-dropdown>
                        <small class="ps-2">Asiento para niño.</small>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3 d-flex flex-column gap-2">
                        <p-dropdown formControlName="voluminousEquipment" [options]="this.valueTrueOrFalse"
                          optionLabel="label" optionValue="value" styleClass="w-100 h-50px">
                          <ng-template let-selected pTemplate="selectedItem">
                            <div class="d-flex">
                              {{selected.label}}
                            </div>
                          </ng-template>
                        </p-dropdown>
                        <small class="ps-2">Equipaje voluminoso.</small>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="mb-3 d-flex flex-column gap-2">
                        <textarea formControlName="specialInstructions" class="w-100" rows="5" cols="30"
                          pInputTextarea></textarea>
                        <small class="ps-2">Instrucciones especiales.</small>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-lg-4">
              <form [formGroup]="formPayment">
                <div class="p-4 border rounded-1">
                  <h4 class="mb-4"><span class="text-body-tertiary">04</span> Información de reservación.</h4>

                  <div class="mb-3 d-flex flex-column gap-2">
                    <input formControlName="typeService" placeholder="" type="text" pInputText class="w-100 h-50px" />
                    <small class="ps-2">Tipo de servicio.</small>
                  </div>
                  <div class="mb-3 d-flex flex-column gap-2">
                    <input formControlName="departure" placeholder="" type="text" pInputText class="w-100 h-50px" />
                    <small class="ps-2">Desde.</small>
                  </div>
                  <div class="mb-3 d-flex flex-column gap-2">
                    <input formControlName="destination" placeholder="" type="text" pInputText class="w-100 h-50px" />
                    <small class="ps-2">Hacia.</small>
                  </div>
                  <div class="d-flex flex-row gap-2">
                    <div class="mb-3 d-flex flex-column gap-2 w-100">
                      <input formControlName="adults" placeholder="" type="text" pInputText class="w-100 h-50px" />
                      <small class="ps-2">Adultos.</small>
                    </div>
                    <div class="mb-3 d-flex flex-column gap-2 w-100">
                      <input formControlName="childrens" placeholder="" type="text" pInputText class="w-100 h-50px" />
                      <small class="ps-2">Niños.</small>
                    </div>
                  </div>
                  <div class="mb-3 d-flex flex-column gap-2">
                    <p-calendar formControlName="arrivalDate" styleClass="w-100 h-50px"
                      inputStyleClass="w-100 h-50px border-0" dateFormat="dd/mm/yy" [showTime]="true"
                      [minDate]="minDate" [numberOfMonths]="1" [touchUI]="true" [stepMinute]="10" [readonlyInput]="true"
                      [hideOnDateTimeSelect]="false" />
                    <small class="ps-2">Fecha y hora de llegada.</small>
                  </div>
                  @if (this.departureDate.value || this.typeService.value === 'ROUNDTRIP') {
                  <div class="mb-3 d-flex flex-column gap-2">
                    <p-calendar formControlName="departureDate" styleClass="w-100 h-50px"
                      inputStyleClass="w-100 h-50px border-0" dateFormat="dd/mm/yy" [showTime]="true"
                      [minDate]="this.arrivalDate.value" [numberOfMonths]="1" [touchUI]="true" [stepMinute]="10"
                      [readonlyInput]="true" [hideOnDateTimeSelect]="false" />
                    <small class="ps-2">Fecha y hora de salida.</small>
                  </div>
                  }

                  <div class="mt-4 pt-3 border-top d-flex flex-column">
                    <h3 class="d-flex justify-content-between align-items-center mb-3">Total: <span
                        class="text-primary">{{this.optionSelected.price | currency: 'MXN'}} MXN</span></h3>

                    <button class="btn btn-link text-muted opacity-50" (click)="prevCallback.emit()"><small>Cambiar
                        vehiculo seleccionado</small></button>

                    <button class="btn btn-primary py-3"
                      [disabled]="this.formPayment.invalid || this.formDetails.invalid" (click)="goToPayment()">Ir al
                      pago</button>

                    <figure class="m-0 mt-3 px-4">
                      <img src="./assets/images/methods_payment_logos.svg" alt="" class="w-100">
                    </figure>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </ng-template>
      </p-stepperPanel>

      <p-stepperPanel header="Completa el pago.">
        <ng-template pTemplate="content" let-prevCallback="prevCallback" let-index="index">
          <div class="border p-3 rounded-3 m-auto text-center py-5 mt-5" style="max-width: 500px;">
            <h2 class="d-flex justify-content-center align-items-center mb-3 fw-bold text-primary">
              {{this.optionSelected.price | currency: 'MXN'}} MXN</h2>

            <button class="btn p-0 border-0" (click)="paymentClip()" [disabled]="this.onLoadPaymentGateway == true">
              <img src="./assets/images/btn_payment_clip.svg" alt="" role="button" class="w-100">
            </button>
            <figure class="m-0 mt-3 px-4">
              <img src="./assets/images/methods_payment_logos.svg" alt="" class="w-100">
            </figure>
          </div>
        </ng-template>
      </p-stepperPanel>
    </p-stepper>
  </div>
</section>

<main-footer></main-footer>