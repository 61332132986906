import { Component, inject } from '@angular/core';
import { MainHeaderComponent } from '@components/main-header/main-header.component';
import { BookingBoxComponent } from '@components/booking-box/booking-box.component';
import { CurrencyPipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { StepperModule } from 'primeng/stepper';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { TransportationService } from '@services/transportation.service';
import { MainFooterComponent } from '@components/main-footer/main-footer.component';

@Component({
  selector: 'app-choose-transfer',
  standalone: true,
  imports: [
    MainHeaderComponent,
    MainFooterComponent,
    BookingBoxComponent,
    CurrencyPipe,
    StepperModule,
    CardModule,
    InputTextModule,
    InputTextareaModule,
    DropdownModule,
    CalendarModule,
    ReactiveFormsModule
  ],
  templateUrl: './choose-transfer.component.html',
  styleUrl: './choose-transfer.component.scss'
})
export class ChooseTransferPage {
  private activatedRoute = inject(ActivatedRoute)
  private formBuilder = inject(FormBuilder)
  private transportationService = inject(TransportationService)

  public minDate: Date = new Date()
  public activeIndexStepper = 0
  public optionSelected: any = null
  public valueTrueOrFalse: any = [{ value: false, label: 'NO' }, { value: true, label: 'SI' }]

  public formDetails = this.formBuilder.group({
    name: [null, Validators.required],
    lastname: [null, Validators.required],
    email: [null, Validators.required],
    phone: [null, Validators.required],
    country: [null, Validators.required],
    city: [null, Validators.required],
    flyArrivalName: [null],
    flyArrivalNumber: [null],
    flyDepartureName: [null],
    flyDepartureNumber: [null],
    childSeat: [false],
    voluminousEquipment: [false],
    specialInstructions: [null],
  })

  public formPayment = this.formBuilder.group({
    typeService: ['ROUNDTRIP', Validators.required],
    departure: [null, Validators.required],
    destination: [null, Validators.required],
    arrivalDate: [null],
    departureDate: [null],
    adults: [1, Validators.required],
    childrens: [0, Validators.required],
    currency: ['MXN', Validators.required],
  })

  get typeService() { return this.formPayment.get('typeService') }
  set typeService(value: any) { this.typeService.setValue(value) }
  get departure() { return this.formPayment.get('departure') }
  set departure(value: any) { this.departure.setValue(value) }
  get destination() { return this.formPayment.get('destination') }
  set destination(value: any) { this.destination.setValue(value) }
  get arrivalDate() { return this.formPayment.get('arrivalDate') }
  set arrivalDate(value: any) { this.arrivalDate.setValue(value) }
  get departureDate() { return this.formPayment.get('departureDate') }
  set departureDate(value: any) { this.departureDate.setValue(value) }
  get adults() { return this.formPayment.get('adults') }
  set adults(value: any) { this.adults.setValue(value) }
  get childrens() { return this.formPayment.get('childrens') }
  set childrens(value: any) { this.childrens.setValue(value) }
  get currency() { return this.formPayment.get('currency') }
  set currency(value: any) { this.currency.setValue(value) }

  public results: any[] = []

  async ngOnInit() {
    this.activatedRoute.params.subscribe(async routeParams => {
      this.typeService?.disable()
      this.departure?.disable()
      this.destination?.disable()
      this.adults?.disable()
      this.childrens?.disable()

      let objParams = this.transportationService.paramsFindRateParse(routeParams)

      this.typeService = objParams.typeService
      this.departure = objParams.departure
      this.destination = objParams.destination
      this.arrivalDate = objParams.dates.arrival
      this.departureDate = objParams.dates.departure
      this.adults = objParams.pax.adults
      this.childrens = objParams.pax.childrens
      this.currency = objParams.currency

      await this.transportationService.findRate(this.typeService.value, this.departure.value, this.destination.value, (new Date(this.arrivalDate.value).getTime() / 1000).toFixed(), (new Date(this.departureDate.value).getTime() / 1000).toFixed(), this.adults.value, this.childrens.value, this.currency.value)
        .then((response: any) => this.results = response)

      for (const element of this.results) {
        element.vehicles = element.vehicles.sort((a: any, b: any) => a.categoryPrice - b.categoryPrice)
      }
    });
  }

  bookingDetails(zone: any, vehicle: any) {
    this.optionSelected = {
      typeService: zone.typeService,
      departure: {
        id: zone.departure.transportationLocationId,
        idZone: zone.departure.transportationZoneId,
        label: zone.departure.label,
        uuid: zone.departure.value,
      },
      destination: {
        id: zone.destination.transportationLocationId,
        idZone: zone.destination.transportationZoneId,
        label: zone.destination.label,
        uuid: zone.destination.value,
      },
      zoneRateId: zone.transportationZoneRateId,
      vehicle: {
        id: vehicle.transportationVehicleId,
        uuid: vehicle.uuid,
        categoryPrice: vehicle.categoryPrice
      },
      pax: {
        adults: this.adults.value,
        childrens: this.childrens.value,
      },
      price: null,
    }

    this.typeService = zone.typeService
    this.departure = zone.departure.label
    this.destination = zone.destination.label

    switch (vehicle.categoryPrice) {
      case 1:
        this.optionSelected.price = zone.category1Price
        break;
      case 2:
        this.optionSelected.price = zone.category2Price
        break;
      case 3:
        this.optionSelected.price = zone.category3Price
        break;
      case 4:
        this.optionSelected.price = zone.category4Price
        break;
      case 5:
        this.optionSelected.price = zone.category5Price
        break;

      default:
        this.optionSelected.price = zone.basePrice
        break;
    }

    this.activeIndexStepper = 1
    window.scrollTo(0, 0);
  }

  goToPayment() {
    this.optionSelected.dates = {
      arrival: (new Date(this.arrivalDate.value).getTime() / 1000).toFixed(),
      departure: (new Date(this.departureDate.value).getTime() / 1000).toFixed()
    }

    this.optionSelected.contactInfo = {
      name: this.formDetails.value.name,
      lastname: this.formDetails.value.lastname,
      email: this.formDetails.value.email,
      phone: this.formDetails.value.phone,
      country: this.formDetails.value.country,
      city: this.formDetails.value.city,
    }

    this.optionSelected.flightInfo = {
      arrival: {
        airlineName: this.formDetails.value.flyArrivalName,
        flyNumber: this.formDetails.value.flyArrivalNumber
      },
      departure: {
        airlineName: this.formDetails.value.flyDepartureName,
        flyNumber: this.formDetails.value.flyDepartureNumber
      }
    }

    this.optionSelected.extras = {
      childSeat: this.formDetails.value.childSeat,
      voluminousEquipment: this.formDetails.value.voluminousEquipment,
      specialInstructions: this.formDetails.value.specialInstructions,
    }

    this.activeIndexStepper = 2
    window.scrollTo(0, 0);
  }

  public onLoadPaymentGateway = false;
  paymentClip() {
    this.onLoadPaymentGateway = true;

    this.optionSelected.paymentMethod = 'clip'

    this.transportationService.createBooking(this.optionSelected)
      .then((response: any) => {
        window.location.replace(response.payment_request_url);
      })
  }
}
